.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #646464;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  position: fixed;
  top: 0px;
  width: 100%;
}

.App-header p{
  font-size: 2em;
  color: white;
  margin-bottom: 0px;
}

.App-link {
  color: #61dafb;
}

#button {
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 20px;
  margin-bottom: 10px;
}

#back-button {
  width: 100px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.size-table {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  width: 70%;
  margin-top: 100px;
}

.size-table span{
  clear: right;
}

.size-table span span{
  margin: 0px;
  
}

.footer {
  background-color: #7b8d93;
  height: 50px;
  position:fixed;
  bottom:0;
  width: 100%;
}

.footer p{
  margin:0 auto;
  padding-top: 10px;
  color: #dbdbdb;
}

.size-item {
  display: flex;
  justify-content: center;

}

.size-item form{
  width: 200px;
  padding-right: 40px;
}

.selector {
  margin-right: 30px;
  position: relative;
}

#cailiao-title {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 10px;
  position: relative;
}

.top {
  height: 0px;
}

.bottom {
  height: 30px;
}

.table-header {
  height: 60px;
}

.table-footer {
  height: 70px;
}

#select-type {
  margin-top: 10px;
  margin-bottom: 10px;
}

.table-name {
  padding-right: 10px;
}

/* .body {
  margin-top: 120px;
  margin-bottom: 60px;
  width: 100%;
} */

